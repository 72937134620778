var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cumple-dia"},[_c('v-data-table',{staticClass:"mt-4",attrs:{"dense":"","headers":_vm.headers,"items":_vm.lista,"footer-props":{ itemsPerPageText: 'Clientes' }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":20,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","rounded":"","dense":"","label":"Fecha","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es-co","color":"primary","first-day-of-week":"1"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1)],1)]},proxy:true},{key:"item.send",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://api.whatsapp.com/send?phone=57" + (item.telefono) + "&text=" + (_vm.msj(
            item
          ))),"target":"_blank"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2",attrs:{"fab":"","color":"success","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-whatsapp")])],1)]}}],null,true)},[_c('span',[_vm._v(" Felicitar en WhatsApp ")])])],1)]}},{key:"item.mail",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2",attrs:{"fab":"","color":"primary","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.notificacion(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-mail")])],1)]}}],null,true)},[_c('span',[_vm._v(" Felicitar al correo ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }