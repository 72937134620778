<template>
  <div class="cumple mx-2">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-card-title>Cumpleaños de clientes</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="12" sm="12">
                <v-tabs v-model="tab">
                  <v-tab href="#tab-1">
                    Cumpleaños del día
                  </v-tab>
                  <v-tab href="#tab-2">
                    Cumpleaños del mes
                  </v-tab>
                  <v-tabs-slider></v-tabs-slider>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item value="tab-1">
                    <cumple-dia />
                  </v-tab-item>
                  <v-tab-item value="tab-2">
                    <cumple-mes />
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CumpleDia from "../../components/cumple/CumpleDia.vue";
import CumpleMes from "../../components/cumple/CumpleMes.vue";
export default {
  components: { CumpleDia, CumpleMes },
  data: () => ({
    tab: "#tab-1",
  }),
  methods: {},
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["recepcion","informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
};
</script>
