<template>
  <div class="cumple-mes">
    <v-data-table
      dense
      :headers="headers"
      :items="lista"
      :footer-props="{ itemsPerPageText: 'Clientes' }"
      class="mt-4"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row dense>
            <v-col cols="4">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="20"
                transition="scale-transition"
                offset-y
                min-width="290px"
                class="mt-2"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    outlined
                    rounded
                    dense
                    label="Fecha"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  scrollable
                  v-model="date"
                  locale="es-co"
                  color="primary"
                  type="month"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-btn color="black" dark @click="notificar()" :loading="loading">
                Notificar por mail a todos
                <v-icon right>mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.send="{ item }">
        <a
          :href="
            `https://api.whatsapp.com/send?phone=57${item.telefono}&text=${msj(
              item
            )}`
          "
          target="_blank"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="success"
                small
                class="my-2"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>
            </template>
            <span>
              Felicitar en WhatsApp
            </span>
          </v-tooltip>
        </a>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  data: () => ({
    clientes: [],
    menu: false,
    date: moment().format("YYYY-MM"),
    loading: false,
    headers: [
      {
        text: "Cliente",
        value: "cliente",
        align: "start",
        sortable: false,
      },
      {
        text: "Cédula",
        value: "cedula",
        sortable: false,
      },
      {
        text: "Contacto",
        value: "telefono",
        sortable: false,
      },
      {
        text: "Correo",
        value: "correo",
        sortable: false,
      },
      {
        text: "Fecha",
        value: "fecha",
        sortable: false,
      },
      {
        text: "Notificar",
        value: "send",
        sortable: false,
      },
    ],
  }),
  methods: {
    loadClientes() {
      const body = {
        route: "/clientes_cumple_mes",
        params: {
          date: moment(this.date).format("MM"),
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.clientes = response.data.data;
        }
      });
    },
    msj(item) {
      return `¡Felicidades ${item.cliente}!. ${this.$store.state.centro.centro} te desea un feliz cumpleaños.`;
    },
    notificar() {
      this.loading = true;
      const ids = this.lista.map((id) => {
        return id.id;
      });
      const body = {
        route: "/notificar_cumple",
        data: {
          ids,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.email_status.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.email_status.data,
            };
            this.$store.dispatch("setAlert", alert);
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.email_status.data,
          };
          this.$store.dispatch("setAlert", alert);
        })
        .finally(() => (this.loading = false));
    },
  },
  created() {
    this.loadClientes();
  },
  computed: {
    lista() {
      // return this.clientes
      //   .filter((cliente) => {
      //     return (
      //       moment(cliente.fecha, "YYYY-MM-DD").format("MM") ==
      //       moment(this.date, "YYYY-MM-DD").format("MM")
      //     );
      //   })
      //   .map((cliente) => {
      //     return {
      //       id: cliente.id,
      //       cliente: cliente.nombre,
      //       fecha: moment(cliente.fecha).format("ll"),
      //       cedula: cliente.cedula,
      //       correo: cliente.email,
      //       telefono: cliente.telefono,
      //     };
      //   })
      //   .sort((a, b) => a.fecha < b.fecha || -1);
      return this.clientes
        .map((cliente) => {
          return {
            id: cliente.id,
            cliente: cliente.nombre,
            fecha: moment(cliente.fecha).format("ll"),
            cedula: cliente.cedula,
            correo: cliente.email,
            telefono: cliente.telefono,
          };
        })
        .sort((a, b) => a.fecha < b.fecha || -1);
    },
  },
  watch: {
    date(val) {
      this.loadClientes();
      return this.date;
    },
  },
};
</script>
